.dark-scheme.de-grey,
.dark-scheme.de-grey footer,
.dark-scheme.de-grey footer a,
.dark-scheme.de-grey .box-url p,
.dark-scheme .profile_wallet{
    color: #a2a2a2;
}

body.dark-scheme.de-grey,
body.dark-scheme.de-grey #content,
.dark-scheme.de-grey section,
.dark-scheme.de-grey header:not(.transparent),
.dark-scheme.de-grey footer,
.dark-scheme.de-grey .feature-box.f-boxed.style-3,
.dark-scheme.de-grey header.header-mobile,
.dark-scheme.de-grey .dropdown li span,
.dark-scheme.de-grey .dropdown li span:hover,
.dark-scheme .owl-prev, .dark-scheme .owl-next,
.dark-scheme.de-grey .modal-content{
    background: #212428;
}

.dark-scheme.de-grey header.smaller,
.dark-scheme.de-grey #mainmenu ul{
    background: rgba(33, 36, 40, .9);
}

.dark-scheme.de-grey .nft__item_extra{
    background: rgba(38, 41, 45, .5);
}

.dark-scheme.de-grey header.smaller{
    -webkit-box-shadow: 0 4px 20px 0 rgba(10,10,10, .8);
    -moz-box-shadow: 0 4px 20px 0 rgba(10,10,10, .8);
    box-shadow: 0 4px 20px 0 rgba(10,10,10, .8);
}

.dark-scheme.de-grey #mainmenu ul{
    -webkit-box-shadow: 0 4px 20px 0 rgba(10,10,10, .4);
    -moz-box-shadow: 0 4px 20px 0 rgba(10,10,10, .4);
    box-shadow: 0 4px 20px 0 rgba(10,10,10, .4);
}

.dark-scheme.de-grey .nft__item.style-2,
.dark-scheme.de-grey .nft_coll,
.dark-scheme.de-grey .nft_pic,
.dark-scheme.de-grey .de_countdown,
.dark-scheme.de-grey .feature-box.f-boxed.style-3,
.dark-scheme.de-grey .activity-list li,
.dark-scheme.de-grey .activity-filter li,
.dark-scheme.de-grey .activity-list li:after,
.dark-scheme.de-grey .nft_img_preview,
.dark-scheme.de-grey .activity-list img,
.dark-scheme.de-grey .owl-prev, .dark-scheme .owl-next,
.dark-scheme.de-grey .nft__item_lg img,
.dark-scheme.de-grey .de-submenu{
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
}

.de-grey .nft__item.style-2,
.dark-scheme.de-grey .nft_coll,
.dark-scheme.de-grey .nft_coll.style-2,
.dark-scheme.de-grey footer,
.de-grey .activity-list li,
.dark-scheme .activity-filter li,
.activity-list li:after,
.de-grey .item_info_counts>div,
.dark-scheme.de-grey .feature-box.f-boxed.style-3{
    background: rgba(255, 255, 255, .025);
    border-top: solid 1px rgba(255, 255, 255, .1);
}

.dark-scheme.de-grey .nft_pic{
    border: none;
}

.dark-scheme.de-grey .nft_coll{
    padding: 10px;
}
.dark-scheme.de-grey .nft_coll_pp img{
    border: none;
}

.dark-scheme.de-grey .nft__item img.nft__item_preview,
.dark-scheme.de-grey .nft_wrap,
.author_list_pp img,
.profile_avatar img,
.d_coll .profile_avatar img{
    box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.4);
    -webkit-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.4);
    -moz-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.4);
}

.dark-scheme.de-grey .de_tab .de_nav li span,
.dark-scheme.de-grey .de-submenu{
    border: solid 1px rgba(255, 255, 255, .1);
    background: #212428;
}

.dark-scheme.de-grey .de_tab.de_steps .de_nav li.active{
    background: rgba(255, 255, 255, .3);
}

header a.btn-main,
.btn-main,
a.btn-main,
a.btn-main:active,
a.btn-main:focus,
a.btn-main:visited,
input[type=button].btn-main,
#quick_search{
    border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
}

#form_subscribe input[type=text] {
    border-radius: 30px 0 0 30px;
    -moz-border-radius: 30px 0 0 30px;
    -webkit-border-radius: 30px 0 0 30px;
}
#form_subscribe #btn-subscribe i {
    border-radius: 0 30px 30px 0;
    -moz-border-radius: 0 30px 30px 0;
    -webkit-border-radius: 0 30px 30px 0;
}

#subheader{
    background: rgba(255, 255, 255, .01);
    border-bottom: solid 1px rgba(255, 255, 255, .1);
}

#form_sb input[type=text],
#form_sb input[type=text]:focus{
    color: #ffffff;
    background: rgba(255, 255, 255, .1);
}


.de-grey #subheader h1{
    display: inline-block;
}

.dark-scheme.de-grey a.btn-main.btn-light{
    background: rgba(255, 255, 255, .1);
    color: #ffffff;
}

.dark-scheme.de-grey .de-menu-notification{
    background: rgba(255, 255, 255, .2);
    color: #ffffff;
}

@media only screen and (max-width: 992px) {

    section:first-child{
        padding-top: 0;
    }

}