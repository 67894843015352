.test {
  position: absolute;
  top: 0px;
  left: 0px;
}

#layer1 {
  z-index: 10;
}
#layer2 {
  z-index: 20;
}

#layer3 {
  z-index: 30;
}

.pulse.animated {
  animation: pulse 1s linear infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
    
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
